import React from "react";
import "./BitListItem.scss";
import BaseComponent from "../../BaseComponent";
import BitStatusIcon from "../BitStatusIcon/BitStatusIcon";
import TextareaAutosize from "react-autosize-textarea";
import BitPriorityIcon from "../BitPriorityIcon/BitPriorityIcon";
import BitListItemOptions from "../BitListItemOptions/BitListItemOptions";
import { connect } from "react-redux";
import {
  BIT_EDITED,
  BIT_PRIORITY_TOGGLED,
  BIT_TOGGLED
} from "../../../redux/actions";
import BitsApi from "../../../utils/api/BitsApi";
import { Draggable } from "react-beautiful-dnd";
import moment from "moment";
import {
  MODIFICATION_TYPE_DEFAULT,
  MODIFICATION_TYPE_TOGGLE
} from "../../../utils/Consts";

class BitListItem_ extends BaseComponent {
  constructor(props) {
    super(props);

    this.textAreaRef = React.createRef();

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onBitToggled = this.onBitToggled.bind(this);
    this.onBitPriorityToggled = this.onBitPriorityToggled.bind(this);

    this.state = {
      displayedText: this.props.task.text
    };
  }

  onChangeInput(event) {
    this.setState({ displayedText: event.target.value });
  }

  onKeyDown(event) {
    if (event.key === "Enter") {
      const id = this.props.task.id;
      const newText = this.state.displayedText;

      this.props.onBitEdited({ id: id, newText: newText });
      BitsApi.editBit(id, newText);

      this.textAreaRef.current.blur();
      event.preventDefault();
    } else if (event.key === "Escape") {
      this.setState({ displayedText: this.props.task.text });
      this.textAreaRef.current.blur();
    }
  }

  onBitToggled(id) {
    this.props.onBitToggled({ id: id });
    BitsApi.toggleBit(id);
  }

  onBitPriorityToggled(id) {
    this.props.onBitPriorityToggled({ id: id });
    BitsApi.togglePriority(id);
  }

  render() {
    const { task } = this.props;
    const highlightTaskChange = moment().unix() - task.modifiedAt <= 1;

    let highlightClass = "";
    if (highlightTaskChange) {
      if (task.modificationType === MODIFICATION_TYPE_TOGGLE) {
        highlightClass = " task-highlight-green";
      } else if (task.modificationType === MODIFICATION_TYPE_DEFAULT) {
        highlightClass = " task-highlight";
      }
    }

    const taskLiClass =
      "task" +
      highlightClass +
      (this.props.isDragDisabled ? "" : " task-draggable");

    let editingHintNode = null;
    if (task.text !== this.state.displayedText) {
      editingHintNode = (
        <div className="task-editing-hint">
          Press <span className="bold">Enter</span> to update bit, or{" "}
          <span className="bold">Escape</span> to discard changes
        </div>
      );
    }

    let itemDecorationNode = null;
    if (this.props.itemDecoration != null) {
      itemDecorationNode = (
        <div className="task-decoration">{this.props.itemDecoration}</div>
      );
    }

    return (
      <Draggable
        draggableId={task.id.toString()}
        index={this.props.index + 1}
        isDragDisabled={this.props.isDragDisabled}
      >
        {(provided, snapshot) => (
          <div
            id={task.id}
            data-dmb-order={task.ordering}
            className={
              taskLiClass + (snapshot.isDragging ? " task-dragged" : "")
            }
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div className="task-drag-handle">
              <i className="fas fa-fw fa-grip-lines-vertical" />
            </div>
            <BitStatusIcon
              done={task.done != null}
              size="medium"
              onIconClicked={() => this.onBitToggled(task.id)}
            />
            <BitPriorityIcon
              priority={task.priority}
              onIconClicked={() => this.onBitPriorityToggled(task.id)}
            />
            <div className="task-content-wrapper">
              {itemDecorationNode}
              <div className="task-content">
                <div className="task-edit-area">
                  <TextareaAutosize
                    id={"task-" + task.id}
                    ref={this.textAreaRef}
                    className="textarea-bit"
                    spellCheck={false}
                    value={this.state.displayedText}
                    onKeyDown={this.onKeyDown}
                    onChange={this.onChangeInput}
                  />
                  {editingHintNode}
                </div>
                <BitListItemOptions
                  task={task}
                  hideItemOptions={this.props.hideItemOptions}
                />
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

const mapDispatch = dispatch => {
  return {
    onBitEdited: data =>
      dispatch({
        type: BIT_EDITED,
        payload: data
      }),
    onBitToggled: data =>
      dispatch({
        type: BIT_TOGGLED,
        payload: data
      }),
    onBitPriorityToggled: data =>
      dispatch({
        type: BIT_PRIORITY_TOGGLED,
        payload: data
      })
  };
};

const BitListItem = connect(null, mapDispatch)(BitListItem_);

export default BitListItem;
