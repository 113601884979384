export default function onBitDeleted(state, payload) {
  let bits = [...state.bits];

  const bitToDelete = bits.find(it => it.id === payload.id);
  bits = bits.filter(it => it.id !== bitToDelete.id);

  bits
    .filter(
      it => it.date === bitToDelete.date && it.ordering > bitToDelete.ordering
    )
    .map(it => {
      it.ordering--;
      return it;
    });

  return Object.assign({}, state, { bits: bits });
}
