import MemCache from "../../utils/MemCache";
import LoginHelper from "../../utils/LoginHelper";
import { initialState } from "../store";

export default function onTokenUpdated(state, payload) {
  let result;
  if (payload.token == null) {
    result = initialState
  } else {
    result = Object.assign({}, state, {
      token: payload.token
    });
  }

  MemCache.setUser({ token: payload.token });
  LoginHelper.setLoginCookie(payload.token);

  return result;
}