import React from "react";
import "./UnauthComponent.scss";
import { Col, Container, Row } from "react-bootstrap";

export function wrapInLoginBox(imageSrc, title, content, formError) {
  return (
    <div className="login-wrapper">
      <header className="login-header">
        <img src="assets/images/logo-vertical.jpg" alt="DoneMyBit logo" />
      </header>
      <Container fluid="lg" className="login-box">
        <Row>
          <Col lg={2} md={1} />
          <Col lg={8} md={10}>
            <div className="login-content">
              <h1 className="centered">{title}</h1>
              {content}
            </div>
            {formError}
          </Col>
          <Col lg={2} md={1} />
        </Row>
      </Container>
      <div className="logo-wrapper">
        <img src="assets/images/logo.jpg" alt="DoneMyBit logo" />
      </div>
    </div>
  );
}
