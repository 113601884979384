import moment from "moment";
import { normaliseMomentWeek } from "../../../utils/DateUtils";

export function calculateExtraTaskPropertiesForAll(tasks) {
  return tasks.map(task => calculateExtraTaskProperties(task));
}

export function calculateExtraTaskProperties(task) {
  if (task.date != null) {
    // date-related properties
    const momentObject = moment(task.date);
    task.year = momentObject.year();
    task.week = momentObject.isoWeek();
    task.weekday = momentObject.isoWeekday();
    task.unix = momentObject.unix();
    task.date = momentObject.format("YYYY-MM-DD");
    task.dateObject = momentObject;

    // task categorisation properties
    const now = moment();
    const midWeek = normaliseMomentWeek(moment());
    const taskMidWeek = normaliseMomentWeek(moment(task.date));
    task.isOverdue = momentObject.diff(now, "day") < 0 && task.done == null;
    task.isToday = momentObject.isSame(now, "day");
    task.isCurrentWeek = momentObject.isSame(now, "isoWeek");
    task.isNextWeek = taskMidWeek.diff(midWeek, "week") === 1;
    task.isPreviousWeek = taskMidWeek.diff(midWeek, "week") === -1;

    // other properties
    if (!task.modifiedAt) {
      task.modificationType = null;
      task.modifiedAt = -1;
    }
  }

  return task;
}

export function mergeNewBits(newTasks, state) {
  const newBitIds = newTasks.map(it => it.id);
  const bits = [...state.bits.filter(it => !newBitIds.includes(it.id))];

  const newBits = calculateExtraTaskPropertiesForAll(newTasks);
  newBits.forEach(it => bits.push(it));

  return bits;
}
