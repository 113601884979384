import React from "react";
import "./TopNavigationBar.scss";
import BaseNavigationBar from "../NavigationBar/BaseNavigationBar";
import { Link } from "react-router-dom";

class TopNavigationBar extends BaseNavigationBar {
  render() {
    let optionsNode = this.createOptions();
    let profileBox = this.createProfileBox();

    return (
      <nav className="topNavigationBar">
        <div className="navigation-hero">
          <Link to="/">
            <img src="/assets/images/logo.jpg" alt="logo" />
          </Link>
        </div>
        {optionsNode}
        {profileBox}
      </nav>
    );
  }
}

export default TopNavigationBar;
