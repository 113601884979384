import React from "react";
import "./EmptyPlaceholder.scss";

class EmptyPlaceholder extends React.Component {
  render() {
    const { image, content } = this.props;
    const contentNode = content();

    return (
      <div className="placeholder-empty">
        <img src={image} alt="" />
        {contentNode}
      </div>
    );
  }
}

export default EmptyPlaceholder;
