import React from "react";
import Sidebar from "../Elements/Sidebar/Sidebar";
import CoreApi from "../../utils/api/CoreApi";
import { CORE_DATA_LOADED } from "../../redux/actions";
import { connect } from "react-redux";
import PageOnboardingUser from "../Pages/PageOnboarding/PageOnboardingUser";
import MemCache from "../../utils/MemCache";
import { Redirect, Route, Switch } from "react-router-dom";
import PageProfile from "../Pages/PageProfile/PageProfile";
import PageBits from "../Pages/PageBits/PageBits";
import PagePriority from "../Pages/PagePriority/PagePriority";
import PageOverdue from "../Pages/PageOverdue/PageOverdue";
import PageNotebook from "../Pages/PageNotebook/PageNotebook";
import PageDashboard from "../Pages/PageDashboard/PageDashboard";
import TopNavigationBar from "../Elements/TopNavigationBar/TopNavigationBar";

class Authenticated_ extends React.Component {
  constructor(props) {
    super(props);

    this.loadCoreData = this.loadCoreData.bind(this);

    this.state = {
      refresh: false
    };
  }

  componentDidMount() {
    this.loadCoreData();
  }

  loadCoreData() {
    const { onContentLoaded } = this.props;

    CoreApi.getCoreData(response => {
      onContentLoaded(response);
      this.setState({ refresh: !this.state.refresh });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.token !== this.props.token) {
      this.loadCoreData();
    }
  }

  render() {
    if (this.props.currentDate === "") {
      // loading
      return null;
    }

    const user = MemCache.getUser();

    if (user.displayName === "") {
      return <PageOnboardingUser onOnboardingSubmitted={this.loadCoreData} />;
    }

    return (
      <section className="content-wrapper">
        <div className="foobar">
          <TopNavigationBar />
          <Sidebar />
          <div className="content">
            <Switch>
              <Route path="/profile" component={PageProfile} />
              <Route path="/bits/:date?" component={PageBits} />
              <Route path="/priority" component={PagePriority} />
              <Route path="/notebook" component={PageNotebook} />
              <Route path="/overdue" component={PageOverdue} />
              <Route exact path="/" component={PageDashboard} />

              <Route>
                <Redirect to="/" />
              </Route>
            </Switch>
          </div>
        </div>
      </section>
    );
  }
}

const mapState = state => {
  return {
    token: state.token,
    currentDate: state.currentDate
  };
};

const mapDispatch = dispatch => {
  return {
    onContentLoaded: content =>
      dispatch({
        type: CORE_DATA_LOADED,
        payload: content
      })
  };
};

const Authenticated = connect(mapState, mapDispatch)(Authenticated_);

export default Authenticated;
