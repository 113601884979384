import React from "react";

import BaseComponent from "../../BaseComponent";
import { LOGOUT, TOKEN_UPDATED } from "../../../redux/actions";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import "./PageProfile.scss";
import UserAvatar from "../../Elements/UserAvatar/UserAvatar";
import MemCache from "../../../utils/MemCache";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { Analytics } from "../../../utils/Analytics";

class PageProfile_ extends BaseComponent {
  constructor(props) {
    super(props);
    Analytics.page("Profile");

    this.logout = this.logout.bind(this);
  }

  logout() {
    Cookies.remove("token");
    this.props.onLogout();
  }

  render() {
    const user = MemCache.getUser();

    return (
      <Container fluid="md">
        <Row>
          <Col sm={2} />
          <Col sm={8} className="profile-wrapper">
            <div className="user-details-wrapper">
              <UserAvatar user={user} size="big" />

              <div className="profile-username">{user.displayName}</div>
              <div className="profile-email">{user.email}</div>
            </div>
            <div
              className="profile-logout button-default"
              onClick={() => this.logout()}
            >
              Logout
            </div>
          </Col>
          <Col sm={2} />
        </Row>
      </Container>
    );
  }
}

const mapState = state => {
  return {
    teams: state.teams
  };
};

const mapDispatch = dispatch => {
  return {
    onLogout: _ =>
      dispatch({
        type: LOGOUT,
        payload: {}
      }),
    onTokenUpdated: payload =>
      dispatch({
        type: TOKEN_UPDATED,
        payload: payload
      })
  };
};

const PageProfile = connect(mapState, mapDispatch)(PageProfile_);

export default PageProfile;
