const developmentBuildConfig = {
  BACKEND_HOST: "http://localhost:4000",
  ANALYTICS: false,
  ANALYTICS_LOGGING: true
};

const productionBuildConfig = {
  BACKEND_HOST: "https://backend.donemybit.com",
  ANALYTICS: true,
  ANALYTICS_LOGGING: false
};

// this class serves the same purpose as .env variables
// https://create-react-app.dev/docs/adding-custom-environment-variables/
let buildConfig;
if (process.env.NODE_ENV === "development") {
  buildConfig = developmentBuildConfig;
} else {
  buildConfig = productionBuildConfig;
}

exports.BuildConfig = buildConfig;
