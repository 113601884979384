import React from "react";

import BaseComponent from "../../BaseComponent";
import Validators from "../../../utils/Validators";
import { wrapInLoginBox } from "../UnauthComponent";
import { EMAIL_ENTERED } from "../../../redux/actions";
import { connect } from "react-redux";
import AuthApi from "../../../utils/api/AuthApi";

class ForgottenPasswordForm_ extends BaseComponent {
  constructor(props) {
    super(props);

    this.onChangeUsernameInput = this.onChangeUsernameInput.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);

    this.state = {
      email: this.props.email,
      error: null
    };
  }

  onChangeUsernameInput(event) {
    const { onEmailEntered } = this.props;
    onEmailEntered(event.target.value);

    this.setState({ error: null });
  }

  onSubmitForm(event) {
    event.preventDefault();

    if (!Validators.isEmail(this.props.email)) {
      this.setState({ error: "Please enter a valid email address" });
    } else {
      AuthApi.forgotPassword(
        this.props.email,
        response => {
          this.props.onEmailSubmitted();
        },
        error => {
          this.props.onEmailSubmitted();
        }
      );
    }
  }

  render() {
    let formError = null;
    if (this.state.error) {
      formError = <div className="form-error big">{this.state.error}</div>;
    }

    return wrapInLoginBox(
      "/assets/images/forgot.svg",
      "Forgot password?",
      <React.Fragment>
        <div className="login-flavour">
          No worries! We'll send you instructions on how to reset your password.
        </div>
        <form onSubmit={this.onSubmitForm}>
          <div className="input-wrapper multiline">
            <input
              type="text"
              placeholder="Email"
              value={this.props.email}
              onChange={this.onChangeUsernameInput}
              autoFocus={true}
            />
            <input type="submit" value="Reset password" />
          </div>
        </form>
      </React.Fragment>,
      formError
    );
  }
}

const mapState = state => {
  return {
    email: state.email
  };
};

const mapDispatch = dispatch => {
  return {
    onEmailEntered: email =>
      dispatch({
        type: EMAIL_ENTERED,
        payload: { email: email }
      })
  };
};

const ForgottenPasswordForm = connect(
  mapState,
  mapDispatch
)(ForgottenPasswordForm_);

export default ForgottenPasswordForm;
