import React from "react";

import BaseComponent from "../../BaseComponent";
import { wrapInLoginBox } from "../UnauthComponent";
import { connect } from "react-redux";

class ForgottenPasswordSubmitted_ extends BaseComponent {
  render() {
    const textPrefix = "We've just sent instructions to ";
    const textPostfix = " on how you can reset your password.";

    return wrapInLoginBox(
      "/assets/images/mail_sent.svg",
      "Please check your inbox",
      <div className="login-flavour">
        {textPrefix}
        <span className="bold">{this.props.email}</span>
        {textPostfix}
      </div>,
      null
    );
  }
}

const mapState = state => {
  return {
    email: state.email
  };
};

const ForgottenPasswordSubmitted = connect(
  mapState,
  null
)(ForgottenPasswordSubmitted_);

export default ForgottenPasswordSubmitted;
