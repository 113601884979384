import React from "react";
import BaseComponent from "../../BaseComponent";
import "./PageNotebook.scss";
import { connect } from "react-redux";
import PageHeader from "../../Elements/PageHeader/PageHeader";
import Container from "react-bootstrap/Container";
import { Analytics } from "../../../utils/Analytics";
import NotebookApi from "../../../utils/api/NotebookApi";
import EmptyPlaceholder from "../../Elements/EmptyPlaceholder/EmptyPlaceholder";
import BitList from "../../Elements/BitList/BitList";
import AddBitFloatingButton from "../../Elements/AddBitFloatingButton/AddBitFloatingButton";
import NewBitFloatingPanel from "../../Elements/NewBitFloatingPanel/NewBitFloatingPanel";
import { NOTEBOOK_BIT_LOADED, NOTEBOOK_LOADED } from "../../../redux/actions";
import { DragDropContext } from "react-beautiful-dnd";
import BitsApi from "../../../utils/api/BitsApi";
import {
  OPTION_JUMP_TO_BIT,
  OPTION_POSTPONE
} from "../../Elements/BitListItemOptions/BitListItemOptions";

class PageNotebook_ extends BaseComponent {
  constructor(props) {
    super(props);
    Analytics.page("Notebook");

    this.showNewBitForm = this.showNewBitForm.bind(this);
    this.hideNewBitForm = this.hideNewBitForm.bind(this);
    this.onBitsReordered = this.onBitsReordered.bind(this);

    this.state = { showNewBitForm: false };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    NotebookApi.getNotebook(response => {
      this.props.onNotebookBitsLoaded({ tasks: response.tasks });
    });
  }

  showNewBitForm() {
    this.setState({ showNewBitForm: true });
  }

  hideNewBitForm() {
    this.setState({ showNewBitForm: false });
  }

  onBitsReordered(result) {
    if (result.destination == null) {
      return;
    }

    const fromPosition = result.source.index;
    const toPosition = result.destination.index;
    const bitId = parseInt(result.draggableId);

    this.props.onNotebookBitMoved({
      bitId: bitId,
      fromPosition: fromPosition,
      toPosition: toPosition
    });
    BitsApi.moveBitNotebook(bitId, fromPosition, toPosition);
  }

  render() {
    if (!this.props.notebookDownloaded) {
      return null;
    }

    let contentNode;
    if (this.props.tasks.length === 0) {
      contentNode = (
        <EmptyPlaceholder
          image={"/assets/images/notebook.svg"}
          content={() => (
            <React.Fragment>
              <p className="placeholder-title">
                Your Notebook is a collection of bits without deadline
              </p>
              <p>Bits completed more than a week ago are hidden.</p>
            </React.Fragment>
          )}
        />
      );
    } else {
      const pendingTasks = this.props.tasks.filter(it => it.done == null);
      let pendingListNode = null;
      if (pendingTasks.length > 0) {
        pendingListNode = (
          <DragDropContext onDragEnd={this.onBitsReordered}>
            <BitList
              extraClass="notebook"
              tasks={this.props.tasks.filter(it => it.done == null)}
              droppableId={1}
              hideItemOptions={[OPTION_JUMP_TO_BIT, OPTION_POSTPONE]}
            />
          </DragDropContext>
        );
      }

      const doneTasks = this.props.tasks.filter(it => it.done != null);
      let doneListNode = null;
      if (doneTasks.length > 0) {
        doneListNode = (
          <DragDropContext onDragEnd={() => {}}>
            <BitList
              extraClass="notebook"
              tasks={this.props.tasks.filter(it => it.done != null)}
              hideItemOptions={[OPTION_JUMP_TO_BIT, OPTION_POSTPONE]}
            />
          </DragDropContext>
        );
      }

      let separatorNode = null;
      if (pendingTasks.length > 0 && doneTasks.length > 0) {
        separatorNode = <hr className="section-separator" />;
      }

      contentNode = (
        <React.Fragment>
          {pendingListNode}
          {separatorNode}
          {doneListNode}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <PageHeader title="Notebook" />
        <Container className="page-content">{contentNode}</Container>
        <AddBitFloatingButton
          hidden={this.state.showNewBitForm}
          onClick={() => this.showNewBitForm()}
        />
        <NewBitFloatingPanel
          hidden={!this.state.showNewBitForm}
          displayedDate={null}
          hideNewBitForm={this.hideNewBitForm}
        />
      </React.Fragment>
    );
  }
}

const mapState = state => {
  return {
    notebookDownloaded: state.notebookDownloaded,
    tasks: state.bits.filter(it => it.date == null)
  };
};

const mapDispatch = dispatch => {
  return {
    onNotebookBitsLoaded: data =>
      dispatch({
        type: NOTEBOOK_LOADED,
        payload: data
      }),
    onNotebookBitMoved: data =>
      dispatch({
        type: NOTEBOOK_BIT_LOADED,
        payload: data
      })
  };
};

const PageNotebook = connect(mapState, mapDispatch)(PageNotebook_);

export default PageNotebook;
