import React from "react";
import ReactDOM from "react-dom";
import BaseComponent from "../../BaseComponent";
import "./FlashMessage.scss";

class FlashMessage extends BaseComponent {
  timeout = null;

  constructor(props) {
    super(props);

    this.state = {
      show: true
    };
  }

  componentDidMount() {
    this.timeout = window.setTimeout(_ => {
      this.setState({ show: false });
    }, 5000);
  }

  componentWillUnmount() {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  render() {
    if (this.state.show) {
      return ReactDOM.createPortal(
        <div className="flash-message">{this.props.children}</div>,
        document.getElementById("flash-root")
      );
    } else {
      return null;
    }
  }
}

export default FlashMessage;
