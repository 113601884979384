import React from "react";
import BaseComponent from "../../BaseComponent";
import "./PageOverdue.scss";
import { connect } from "react-redux";
import PageHeader from "../../Elements/PageHeader/PageHeader";
import Container from "react-bootstrap/Container";
import { Analytics } from "../../../utils/Analytics";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverdueApi from "../../../utils/api/OverdueApi";
import EmptyPlaceholder from "../../Elements/EmptyPlaceholder/EmptyPlaceholder";
import { OVERDUE_LOADED } from "../../../redux/actions";
import BitListSection from "../../Elements/BitListSection/BitListSection";

class PageOverdue_ extends BaseComponent {
  constructor(props) {
    super(props);
    Analytics.page("Overdue");
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    OverdueApi.getOverdueBits(response => {
      this.props.onOverdueLoaded({ tasks: response.tasks });
    });
  }

  getTaskOrdering(tasks) {
    return tasks.sort((a, b) => {
      if (a.unix === b.unix) {
        return a.ordering - b.ordering;
      }
      return b.unix - a.unix;
    });
  }

  render() {
    if (this.props.tasks == null) {
      return null;
    }

    let contentNode;
    if (this.props.tasks.length === 0) {
      contentNode = (
        <EmptyPlaceholder
          image={"/assets/images/empty_overdue.svg"}
          content={() => (
            <React.Fragment>
              <p className="placeholder-title">
                Hurray, you do not have any overdue bits
              </p>
              <p>It's time to celebrate your hard work!</p>
            </React.Fragment>
          )}
        />
      );
    } else {
      const thisWeekNode = (
        <BitListSection
          sectionTitle="From this week"
          sectionIcon="fa-calendar-day"
          getTaskOrdering={this.getTaskOrdering}
          tasks={this.props.tasks.filter(it => it.isCurrentWeek)}
        />
      );

      const lastWeekNode = (
        <BitListSection
          sectionTitle="From previous week"
          sectionIcon="fa-calendar-day"
          getTaskOrdering={this.getTaskOrdering}
          tasks={this.props.tasks.filter(it => it.isPreviousWeek)}
        />
      );

      const earlierNode = (
        <BitListSection
          sectionTitle="Long overdue"
          sectionIcon="fa-angle-right"
          getTaskOrdering={this.getTaskOrdering}
          tasks={this.props.tasks.filter(
            it => !it.isCurrentWeek && !it.isPreviousWeek
          )}
        />
      );

      contentNode = (
        <React.Fragment>
          {thisWeekNode}
          {lastWeekNode}
          {earlierNode}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <PageHeader title="Bits overdue" />
        <Container className="page-content">
          <Row>
            <Col sm={12} className="bit-list no-padding">
              {contentNode}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapState = state => {
  let overdueTasks = null;
  if (state.overdueBitIds != null) {
    overdueTasks = state.bits.filter(
      bit => bit.date != null && state.overdueBitIds.includes(bit.id)
    );
  }

  return { tasks: overdueTasks };
};

const mapDispatch = dispatch => {
  return {
    onOverdueLoaded: data =>
      dispatch({
        type: OVERDUE_LOADED,
        payload: data
      })
  };
};

const PageOverdue = connect(mapState, mapDispatch)(PageOverdue_);

export default PageOverdue;
