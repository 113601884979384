import moment from "moment";
import { MODIFICATION_TYPE_DEFAULT } from "../../utils/Consts";

export default function onBitPriorityToggled(state, payload) {
  const bits = [...state.bits];

  const bitToToggle = bits.find(it => it.id === payload.id);
  if (bitToToggle != null) {
    bitToToggle.priority = 1 - bitToToggle.priority;
    if (bitToToggle.priority) {
      bitToToggle.modificationType = MODIFICATION_TYPE_DEFAULT;
      bitToToggle.modifiedAt = moment().unix();
    }
  }

  return Object.assign({}, state, { bits: bits });
}
