import React from "react";

import BaseComponent from "../../BaseComponent";
import { wrapInLoginBox } from "../UnauthComponent";

class RegisterSubmitted extends BaseComponent {
  render() {
    const textPrefix = "We've just sent an activation email to your address ";
    const textPostfix =
      ". Please follow the instructions described there to activate your account.";

    return wrapInLoginBox(
      "/assets/images/mail_sent.svg",
      "You're almost there",
      <div className="login-flavour">
        {textPrefix}
        <span className="bold">{this.props.email}</span>
        {textPostfix}
      </div>,
      null
    );
  }
}

export default RegisterSubmitted;
