import React from "react";
import "./BaseComponent.scss";
import MemCache from "../utils/MemCache";
import { Link } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Analytics } from "../utils/Analytics";
import { formatDateYYYYW } from "../utils/DateUtils";

class BaseComponent extends React.Component {
  getCurrentUserId() {
    return MemCache.getUser().id;
  }

  createPageOptions(options) {
    return options.map(it =>
      this.createPageOption(it.link, it.icon, it.tooltip, it.analytics)
    );
  }

  createPageOption(link, iconClass, tooltipText, analytics = {}) {
    const iconWithOverlay = this.withOverlay(
      <Link
        to={link}
        onClick={() => Analytics.ui(analytics.name, analytics.props)}
      >
        <i className={"fas fa-fw " + iconClass} />
      </Link>,
      iconClass,
      tooltipText
    );

    return <li key={iconClass}>{iconWithOverlay}</li>;
  }

  withOverlay(node, tooltipId, tooltipText) {
    const tooltip = (
      <Tooltip id={"tooltip-" + tooltipId} className="dmb-tooltip">
        {tooltipText}
      </Tooltip>
    );

    return (
      <OverlayTrigger overlay={tooltip} placement="bottom">
        {node}
      </OverlayTrigger>
    );
  }

  getTaskLink(task) {
    const date = formatDateYYYYW(task.date);
    return "/bits/" + date;
  }
}

export default BaseComponent;
