import { calculateExtraTaskProperties } from "./utils/ReducerUtils";

export default function onBitAdded(state, payload) {
  const bits = [...state.bits];

  if (payload.bit.date == null) {
    payload.bit.ordering = bits.filter(it => it.date == null && it.done == null).length + 1;
    bits.push(payload.bit);
  } else {
    const newBit = calculateExtraTaskProperties(payload.bit);
    newBit.ordering = bits.filter(it => it.date === payload.bit.date).length + 1;
    bits.push(newBit);
  }

  return Object.assign({}, state, { bits: bits });
}
