import React from "react";

import BaseComponent from "../../BaseComponent";
import "./PageOnboardingUser.scss";
import { wrapInLoginBox } from "../UnauthComponent";
import { Redirect } from "react-router-dom";
import Validators from "../../../utils/Validators";
import OnboardingApi from "../../../utils/api/OnboardingApi";
import { TOKEN_UPDATED } from "../../../redux/actions";
import { connect } from "react-redux";
import { Analytics } from "../../../utils/Analytics";

class PageOnboardingUser_ extends BaseComponent {
  constructor(props) {
    super(props);
    Analytics.page("Onboarding");

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);

    this.state = {
      displayName: "",
      error: null
    };
  }

  formatDisplayName(name) {
    let splitName = name.toLowerCase().split(" ");
    for (let i = 0; i < splitName.length; i++) {
      splitName[i] =
        splitName[i].charAt(0).toUpperCase() + splitName[i].substring(1);
    }
    return splitName.join(" ");
  }

  onChangeInput(event) {
    this.setState({
      displayName: this.formatDisplayName(event.target.value),
      error: null
    });
  }

  onSubmitForm(event) {
    event.preventDefault();

    if (Validators.isDisplayName(this.state.displayName)) {
      OnboardingApi.submitDisplayName(this.state.displayName, response => {
        const { token } = response;
        const { onAuthenticated } = this.props;
        onAuthenticated(token);

        this.props.onOnboardingSubmitted();
      });
    } else {
      this.setState({ error: "Please choose a name of at least 2 characters" });
    }
  }

  render() {
    let formError = null;
    if (this.state.error != null) {
      formError = <div className="form-error big">{this.state.error}</div>;
    }

    return wrapInLoginBox(
      "/assets/images/customise.svg",
      "How should we call you?",
      <React.Fragment>
        <div className="login-flavour">
          <Redirect to="/" />
          Your name will only appear in personalized messages.
        </div>
        <form onSubmit={this.onSubmitForm}>
          <div className="input-wrapper multiline">
            <input
              type="text"
              placeholder="Nickname or full name"
              value={this.state.displayName}
              onChange={this.onChangeInput}
              autoFocus={true}
            />
            <input type="submit" value="Continue" />
          </div>
        </form>
      </React.Fragment>,
      formError
    );
  }
}

const mapDispatch = dispatch => {
  return {
    onAuthenticated: token =>
      dispatch({
        type: TOKEN_UPDATED,
        payload: { token: token }
      })
  };
};

const PageOnboardingUser = connect(null, mapDispatch)(PageOnboardingUser_);

export default PageOnboardingUser;
