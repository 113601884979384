import BaseApi from "./BaseApi";
import { Analytics } from "../Analytics";

class OnboardingApi extends BaseApi {
  static submitDisplayName(name, then, error) {
    Analytics.action("User onboarded", { nameLength: name.length });
    return this.post("/onboarding", { displayName: name }, then, error);
  }
}

export default OnboardingApi;
