import BaseApi from "./BaseApi";
import { Analytics } from "../Analytics";

class AuthApi extends BaseApi {
  static login(username, password, then, error) {
    Analytics.action("User logged in");
    this.post(
      "/login",
      { username: username, password: password },
      then,
      error
    );
  }

  static forgotPassword(username, then, error) {
    Analytics.action("User password forgotten");
    this.post("/forgot-password", { username: username }, then, error);
  }

  static resetPassword(code, password, then, error) {
    Analytics.action("User password reset");
    this.post(
      "/reset-password",
      { code: code, password: password },
      then,
      error
    );
  }

  static register(email, password, then, error) {
    Analytics.action("User registered");
    this.post(
      "/register",
      {
        email: email,
        password: password
      },
      then,
      error
    );
  }

  static confirm(code, then, error) {
    Analytics.action("User registration confirmed");
    this.post("/register/confirm", { code: code }, then, error);
  }
}

export default AuthApi;
