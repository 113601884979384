import moment from "moment";
import { MODIFICATION_TYPE_DEFAULT } from "../../utils/Consts";

export default function onBitMovedNotebook(state, payload) {
  let bits = [...state.bits];

  const movedBit = bits.find(it => it.id === payload.bitId);

  bits
    .filter(
      it =>
        it.id !== movedBit.id &&
        it.date == null &&
        it.ordering > payload.fromPosition
    )
    .map(bit => {
      bit.ordering--;
      return bit;
    });

  bits
    .filter(
      it =>
        it.id !== movedBit.id &&
        it.date == null &&
        it.ordering >= payload.toPosition
    )
    .map(bit => {
      bit.ordering++;
      return bit;
    });

  if (movedBit != null) {
    movedBit.ordering = payload.toPosition;
    movedBit.modificationType = MODIFICATION_TYPE_DEFAULT;
    movedBit.modifiedAt = moment().unix();
  }

  return Object.assign({}, state, { bits: bits });
}
