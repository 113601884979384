import { calculateExtraTaskPropertiesForAll } from "./utils/ReducerUtils";

export default function onBitsLoaded(state, payload) {
  const newIds = payload.tasks.map(it => it.id);
  const bits = [...state.bits.filter(it => !newIds.includes(it.id))];

  const newBits = calculateExtraTaskPropertiesForAll(payload.tasks);
  newBits.forEach(it => bits.push(it));

  const weeksLoaded = [...state.weeksLoaded];
  const date = payload.year + "-" + payload.week;
  if (!weeksLoaded.includes(date)) {
    weeksLoaded.push(date);
  }

  return Object.assign({}, state, { bits: bits, weeksLoaded: weeksLoaded });
}
