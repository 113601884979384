import React from "react";
import BaseComponent from "../../BaseComponent";
import "./BitListSection.scss";
import { DragDropContext } from "react-beautiful-dnd";
import BitList from "../BitList/BitList";
import { OPTION_POSTPONE } from "../BitListItemOptions/BitListItemOptions";
import { Link } from "react-router-dom";
import moment from "moment";
import { normaliseMomentDay } from "../../../utils/DateUtils";

class BitListSection extends BaseComponent {
  now = normaliseMomentDay(moment());

  constructor(props) {
    super(props);
    this.getTaskOrdering = this.getTaskOrdering.bind(this);
    this.getItemDecoration = this.getItemDecoration.bind(this);
  }

  getTaskOrdering(tasks) {
    if (this.props.getTaskOrdering == null) {
      return tasks.sort((a, b) => a.id - b.id);
    } else {
      return this.props.getTaskOrdering(tasks);
    }
  }

  getItemDecoration(task) {
    const taskDateObject = moment(task.date);
    const dayDifference = this.now.diff(
      normaliseMomentDay(taskDateObject),
      "days"
    );
    const dayWording = dayDifference > 1 ? "days" : "day";

    if (dayDifference === 0) {
      return null;
    }

    const linkNode = (
      <Link to={this.getTaskLink(task)}>
        {taskDateObject.format("MMMM DD.")}
      </Link>
    );

    if (dayDifference > 0) {
      return (
        <span>
          {linkNode} ({dayDifference} {dayWording} ago)
        </span>
      );
    } else {
      return linkNode;
    }
  }

  render() {
    if (this.props.tasks.length === 0) {
      return null;
    }

    let sectionIconNode = null;
    if (this.props.sectionIcon) {
      sectionIconNode = <i className={"fas fa-fw " + this.props.sectionIcon} />;
    }

    return (
      <React.Fragment>
        <div className="section-title">
          {sectionIconNode}
          <h2>{this.props.sectionTitle}</h2>
        </div>
        <DragDropContext onDragEnd={() => {}}>
          <BitList
            tasks={this.props.tasks}
            extraClass="items-loose"
            getItemDecoration={this.getItemDecoration}
            getTaskOrdering={this.getTaskOrdering}
            hideItemOptions={[OPTION_POSTPONE]}
          />
        </DragDropContext>
      </React.Fragment>
    );
  }
}

export default BitListSection;
