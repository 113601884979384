import React from "react";
import "./TimelineBadge.scss";

class TimelineBadge extends React.Component {
  render() {
    const { date, isToday } = this.props;

    let timelineBadgeClass = "timeline-badge";
    if (isToday) {
      timelineBadgeClass += " today";
    }

    const dayText = date.format("D");
    const dayOrdinalIndicator = date.format("Do").substring(dayText.length);

    return (
      <div className={timelineBadgeClass}>
        <div className="weekday">{date.format("dd")}</div>
        <div className="date">
          <span className="strong">{dayText}</span>
          <span className="ordinal">{dayOrdinalIndicator}</span>
        </div>
      </div>
    );
  }
}

export default TimelineBadge;
