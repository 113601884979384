import React from "react";
import "./NewBitInput.scss";
import BaseComponent from "../../BaseComponent";
import moment from "moment";
import BitsApi from "../../../utils/api/BitsApi";
import { connect } from "react-redux";
import { BIT_ADDED, BIT_ID_UPDATED } from "../../../redux/actions";
import { MODIFICATION_TYPE_DEFAULT } from "../../../utils/Consts";

class NewBitInput_ extends BaseComponent {
  _input;
  taskTemporaryId = 1;

  constructor(props) {
    super(props);

    this.createDayOptions = this.createDayOptions.bind(this);
    this.getSelectedDate = this.getSelectedDate.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);

    this.state = {
      text: "",
      selectedDayOrdinal: moment().isoWeekday()
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.hidden) {
      this._input.focus();
    }
  }

  getSelectedDate() {
    if (this.props.displayedDate == null) {
      return null;
    } else {
      return moment(this.props.displayedDate, "YYYY-W")
        .isoWeekday(this.state.selectedDayOrdinal)
        .format("YYYY-MM-DD");
    }
  }

  onChangeInput(event) {
    this.setState({ text: event.target.value });
  }

  onKeyDown(event) {
    if (event.key === "Escape") {
      this.cancelForm();
    }
  }

  cancelForm() {
    this.setState({
      text: "",
      selectedDayOrdinal: moment().isoWeekday()
    });
    this.props.hideNewBitForm();
  }

  onSubmitForm(event) {
    event.preventDefault();

    if (this.state.text.length > 0) {
      const tempId = "tmp-" + this.taskTemporaryId++;
      const selectedDate = this.getSelectedDate();

      this.props.onBitAdded({
        date: this.props.displayedDate,
        bit: {
          id: tempId,
          userId: this.getCurrentUserId(),
          date: selectedDate,
          text: this.state.text,
          done: null,
          priority: 0,
          deleted: 0,
          ordering: 0,
          modificationType: MODIFICATION_TYPE_DEFAULT,
          modifiedAt: moment().unix()
        }
      });

      this.setState({ text: "" });
      this.props.hideNewBitForm();

      BitsApi.addBit(tempId, this.state.text, selectedDate, response => {
        this.props.onBitIdUpdated({
          tempId: response.tempId,
          newId: response.id,
          ordering: response.ordering
        });
      });
    }
  }

  onDayClicked(dayOrdinal) {
    this.setState({
      selectedDayOrdinal: dayOrdinal
    });
    this._input.focus();
  }

  createDayOptions() {
    return [1, 2, 3, 4, 5, 6, 7].map(ordinal => {
      const liClass =
        "task-day-option" +
        (ordinal === this.state.selectedDayOrdinal ? " selected" : "");

      return (
        <li
          key={ordinal}
          className={liClass}
          onClick={() => this.onDayClicked(ordinal)}
        >
          {moment
            .weekdays(ordinal)
            .substring(0, 2)
            .toUpperCase()}
        </li>
      );
    });
  }

  render() {
    let buttonClass = null;
    if (this.state.text.length > 0) {
      buttonClass = "ready";
    }

    let inputPlaceholder = "Add a new bit";
    let daySelectorNode = null;
    let notebookNoteNode = null;
    if (this.props.displayedDate == null) {
      inputPlaceholder += " to your Notebook";
      notebookNoteNode = <div className="new-bit-note">&nbsp;</div>;
    } else {
      const dayOptions = this.createDayOptions();
      daySelectorNode = <ul className="task-day-selector">{dayOptions}</ul>;
    }

    return (
      <React.Fragment>
        <form className="new-task" onSubmit={this.onSubmitForm}>
          <div className="task-essentials">
            <div className="task-content">
              <input
                type="text"
                placeholder={inputPlaceholder}
                value={this.state.text}
                onChange={this.onChangeInput}
                onKeyDown={this.onKeyDown}
                ref={input => (this._input = input)}
              />
              {daySelectorNode}
              {notebookNoteNode}
            </div>
            <div className="submit-wrapper">
              <button type="submit" className={buttonClass}>
                Add
              </button>
              <div className="cancel" onClick={this.cancelForm}>
                Cancel
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

const mapDispatch = dispatch => {
  return {
    onBitAdded: bit =>
      dispatch({
        type: BIT_ADDED,
        payload: bit
      }),
    onBitIdUpdated: data =>
      dispatch({
        type: BIT_ID_UPDATED,
        payload: data
      })
  };
};

const NewBitInput = connect(null, mapDispatch)(NewBitInput_);

export default NewBitInput;
