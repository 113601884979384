import React from "react";
import "./PagePriority.scss";
import { connect } from "react-redux";
import BaseComponent from "../../BaseComponent";
import { Analytics } from "../../../utils/Analytics";
import PriorityApi from "../../../utils/api/PriorityApi";
import Container from "react-bootstrap/Container";
import PageHeader from "../../Elements/PageHeader/PageHeader";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import EmptyPlaceholder from "../../Elements/EmptyPlaceholder/EmptyPlaceholder";
import { PRIORITY_LOADED } from "../../../redux/actions";
import BitListSection from "../../Elements/BitListSection/BitListSection";

class PagePriority_ extends BaseComponent {
  constructor(props) {
    super(props);
    Analytics.page("Priority");
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    PriorityApi.getPriorityBits(response => {
      this.props.onPriorityLoaded({ tasks: response.tasks });
    });
  }

  getTaskOrdering(tasks) {
    return tasks.sort((a, b) => {
      if (a.unix === b.unix) {
        return a.ordering - b.ordering;
      }
      return a.unix - b.unix;
    });
  }

  render() {
    if (this.props.tasks == null) {
      return null;
    }

    let contentNode;
    if (this.props.tasks.length === 0) {
      contentNode = (
        <EmptyPlaceholder
          image={"/assets/images/empty_priority.svg"}
          content={() => (
            <React.Fragment>
              <p className="placeholder-title">
                You do not have any uncompleted bits marked as priority.
              </p>
              <p>
                Use the <i className="fas fa-fw fa-star" /> icon to mark bits as
                priority.
              </p>
            </React.Fragment>
          )}
        />
      );
    } else {
      const overdueNode = (
        <BitListSection
          sectionTitle="Overdue"
          sectionIcon="fa-calendar-day"
          getTaskOrdering={this.getTaskOrdering}
          tasks={this.props.tasks.filter(it => it.isOverdue)}
        />
      );

      const todayNode = (
        <BitListSection
          sectionTitle="Today"
          sectionIcon="fa-home"
          getTaskOrdering={this.getTaskOrdering}
          tasks={this.props.tasks.filter(it => it.isToday)}
        />
      );

      const thisWeekNode = (
        <BitListSection
          sectionTitle="Later this week"
          sectionIcon="fa-angle-right"
          getTaskOrdering={this.getTaskOrdering}
          tasks={this.props.tasks.filter(it => it.isCurrentWeek && !it.isToday && !it.isOverdue)}
        />
      );

      const nextWeekNode = (
        <BitListSection
          sectionTitle="Upcoming next week"
          sectionIcon="fa-angle-right"
          getTaskOrdering={this.getTaskOrdering}
          tasks={this.props.tasks.filter(it => it.isNextWeek)}
        />
      );

      contentNode = (
        <React.Fragment>
          {overdueNode}
          {todayNode}
          {thisWeekNode}
          {nextWeekNode}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <PageHeader title="Priority bits" />
        <Container className="page-content">
          <Row>
            <Col sm={12} className="bit-list no-padding">
              {contentNode}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapState = state => {
  let priorityTasks = null;
  if (state.priorityBitIds != null) {
    priorityTasks = state.bits.filter(
      bit => bit.date != null && state.priorityBitIds.includes(bit.id)
    );
  }

  return { tasks: priorityTasks };
};

const mapDispatch = dispatch => {
  return {
    onPriorityLoaded: data =>
      dispatch({
        type: PRIORITY_LOADED,
        payload: data
      })
  };
};

const PagePriority = connect(mapState, mapDispatch)(PagePriority_);

export default PagePriority;
