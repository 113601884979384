import moment from "moment";
import Cookies from "js-cookie";
import { COOKIE_TOKEN } from "./Cookies";

class LoginHelper {
  static setLoginCookie(token) {
    const tokenExpiryDate = new Date(
      moment()
        .add(14, "days")
        .unix() * 1000
    );
    Cookies.set(COOKIE_TOKEN, token, { expires: tokenExpiryDate });
  }
}

export default LoginHelper;
