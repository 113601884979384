import mixpanel from "mixpanel-browser";
import { BuildConfig } from "./BuildConfig";

mixpanel.init("cf9b5302769ec69553311e6ca427f518");

let actions = {
  identify: id => {
    if (BuildConfig.ANALYTICS) {
      mixpanel.identify(id);
    }

    if (BuildConfig.ANALYTICS_LOGGING) {
      console.log("ANALYTICS | identify " + id);
    }
  },
  page: name => {
    actions.track("Page: " + name);
  },
  action: (name, props) => {
    actions.track("Action: " + name, props);
  },
  ui: (name, props) => {
    if (name) {
      actions.track("Ui: " + name, props);
    }
  },
  track: (name, props) => {
    if (BuildConfig.ANALYTICS) {
      mixpanel.track(name, props);
    }

    if (BuildConfig.ANALYTICS_LOGGING) {
      console.log("ANALYTICS | " + name);
      if (props) {
        console.log(props);
      }
    }
  },
  setUserProperties: props => {
    if (BuildConfig.ANALYTICS) {
      mixpanel.people.set(props);
    }

    if (BuildConfig.ANALYTICS_LOGGING) {
      console.log("ANALYTICS | user props set");
      console.log(props);
    }
  }
};

export let Analytics = actions;
