import axios from "axios";
import store from "../../redux/store";
import { TOKEN_UPDATED } from "../../redux/actions";
import MemCache from "../MemCache";
import { BuildConfig } from "../BuildConfig";
import _ from "lodash";
import FatalErrorHandler from "../FatalErrorHandler";

class BaseApi {
  static api = axios.create({
    baseURL: BuildConfig.BACKEND_HOST,
    responseType: "json"
  });

  static getConfig() {
    return {
      headers: {
        Authorization: `Bearer ${MemCache.getUser().token}`,
        XTimezone: new Date().getTimezoneOffset()
      },
      timeout: 8000
    };
  }

  static get(path, then, error) {
    this.handleHttpRequest(this.api.get(path, this.getConfig()), then, error);
  }

  static post = _.debounce((path, params, then, error) => {
    BaseApi.postImmediate(path, params, then, error);
  }, 300);

  static postImmediate(path, params, then, error) {
    this.handleHttpRequest(
      this.api.post(path, params, this.getConfig()),
      then,
      error
    );
  }

  static handleHttpRequest(request, then, error) {
    return request
      .then(response => {
        if (then && response) {
          try {
            then(response.data);
          } catch (error) {
            FatalErrorHandler.onFatalError(error);
          }
        }
      })
      .catch(reason => {
        if (error) {
          error(reason.response);
        } else if (
          reason &&
          reason.response &&
          reason.response.status === 401
        ) {
          store.dispatch({
            type: TOKEN_UPDATED,
            payload: { token: null }
          });
        } else {
          FatalErrorHandler.onFatalError(reason);
        }
      });
  }
}

export default BaseApi;
