import React from "react";
import BaseComponent from "../../BaseComponent";
import "./PageRegister.scss";
import RegisterForm from "./RegisterForm";
import RegisterSubmitted from "./RegisterSubmitted";
import { Analytics } from "../../../utils/Analytics";

class PageRegister extends BaseComponent {
  constructor(props) {
    super(props);
    Analytics.page("Register");

    this.onRegistrationSubmitted = this.onRegistrationSubmitted.bind(this);

    this.state = {
      submitted: null
    };
  }

  onRegistrationSubmitted(email) {
    this.setState({ submitted: email });
  }

  render() {
    if (this.state.submitted == null) {
      return (
        <RegisterForm onRegistrationSubmitted={this.onRegistrationSubmitted} />
      );
    } else {
      return <RegisterSubmitted email={this.state.submitted} />;
    }
  }
}

export default PageRegister;
