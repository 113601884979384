import React from "react";
import "./BitList.scss";
import BitListItem from "../BitListItem/BitListItem";
import BaseComponent from "../../BaseComponent";
import { Droppable } from "react-beautiful-dnd";

class BitList extends BaseComponent {
  constructor(props) {
    super(props);
    this.getTasksInOrder = this.getTasksInOrder.bind(this);
  }

  getTasksInOrder() {
    if (this.props.getTaskOrdering == null) {
      return this.props.tasks.sort((a, b) => a.ordering - b.ordering);
    } else {
      return this.props.getTaskOrdering(this.props.tasks);
    }
  }

  render() {
    const { extraClass } = this.props;

    let listClass = "taskList";
    if (extraClass) {
      listClass += " " + extraClass;
    }

    let droppableId;
    let isDragDisabled = false;
    if (this.props.droppableId != null) {
      droppableId = this.props.droppableId.toString();
    } else {
      droppableId = "droppable-id";
      isDragDisabled = true;
    }

    return (
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <div
            className={
              listClass +
              (snapshot.isDraggingOver ? " taskList-dragging-over" : "")
            }
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {this.getTasksInOrder().map((task, index) => {
              let itemDecoration = null;
              if (this.props.getItemDecoration != null) {
                itemDecoration = this.props.getItemDecoration(task);
              }

              return (
                <BitListItem
                  key={"ti-" + task.id}
                  task={task}
                  index={index}
                  isDragDisabled={isDragDisabled}
                  itemDecoration={itemDecoration}
                  hideItemOptions={this.props.hideItemOptions}
                  random={Math.random()} // hacky - editing a bit didn't trigger an update
                />
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

export default BitList;
