import moment from "moment";
import { calculateExtraTaskProperties } from "./utils/ReducerUtils";
import { MODIFICATION_TYPE_DEFAULT } from "../../utils/Consts";

export default function onBitMoved(state, payload) {
  let bits = [...state.bits];

  const movedBit = bits.find(it => it.id === payload.bitId);

  bits
    .filter(
      it =>
        it.id !== movedBit.id &&
        it.year === payload.source.year &&
        it.week === payload.source.week &&
        it.weekday === payload.source.weekdayIndex &&
        it.ordering > payload.source.position
    )
    .map(bit => {
      bit.ordering--;
      return bit;
    });

  bits
    .filter(
      it =>
        it.id !== movedBit.id &&
        it.year === payload.destination.year &&
        it.week === payload.destination.week &&
        it.weekday === payload.destination.weekdayIndex &&
        it.ordering >= payload.destination.position
    )
    .map(bit => {
      bit.ordering++;
      return bit;
    });

  if (movedBit != null) {
    movedBit.ordering = payload.destination.position;
    movedBit.weekday = payload.destination.weekdayIndex;
    movedBit.date = moment()
      .year(payload.destination.year)
      .isoWeek(payload.destination.week)
      .isoWeekday(payload.destination.weekdayIndex)
      .format("YYYY-MM-DD");
    movedBit.modificationType = MODIFICATION_TYPE_DEFAULT;
    movedBit.modifiedAt = moment().unix();
    calculateExtraTaskProperties(movedBit);
  }

  return Object.assign({}, state, { bits: bits });
}
