export const KEY_LOGIN = "login";

export const VALUE_PASSWORD_RESET = "password-reset";
export const VALUE_REGISTRATION_CONFIRMED = "registration-confirmError";

class FlashStore {
  static flashStore = {};

  static put(key, value) {
    this.flashStore[key] = value;
  }

  static get(key) {
    const result = this.flashStore[key];
    if (result) {
      delete this.flashStore[key];
    }

    return result;
  }
}

export default FlashStore;
