export default function onNotebookLoaded(state, payload) {
  const newIds = payload.tasks.map(it => it.id);
  const bits = [...state.bits.filter(it => !newIds.includes(it.id))];
  payload.tasks.forEach(it => bits.push(it));

  return Object.assign({}, state, {
    bits: bits,
    notebookDownloaded: true
  });
}
