import { Analytics } from "./Analytics";

class MemCache {
  static user = {};

  static setUser(value) {
    this.user = Object.assign(this.user, value);

    if (this.user.id) {
      Analytics.identify(this.user.id);
      Analytics.setUserProperties({
        email: this.user.email,
        name: this.user.displayName,
        initials: this.user.initials
      });
    }
  }

  static updateUser(updateValue) {
    this.user = Object.assign({}, this.user, updateValue);
  }

  static getUser() {
    return this.user;
  }

  static clear() {
    this.user = {};
    this.team = {};
  }
}

export default MemCache;
