import { createStore } from "redux";
import reducers from "./redux";

export const initialState = {
  currentDate: "",
  bits: [],
  weeksLoaded: [],

  priorityBitIds: null,
  overdueBitIds: null,
  notebookDownloaded: false,

  email: "",
  token: null,
};

const store = createStore(reducers);
export default store;
