import React from "react";
import "./MonthSeparator.scss";

class MonthSeparator extends React.Component {

    render() {
        return <div className="month-separator">End of {this.props.month}</div>;
    }
}

export default MonthSeparator;
