export default function onBitIdUpdated(state, payload) {
  const bits = [...state.bits];
  const bitToUpdate = bits.find(it => it.id === payload.tempId);
  if (bitToUpdate != null) {
    bitToUpdate.id = payload.newId;
    bitToUpdate.ordering = payload.ordering;
  }

  return Object.assign({}, state, { bits: bits });
}
