import React from "react";

import BaseComponent from "../../BaseComponent";
import { wrapInLoginBox } from "../UnauthComponent";
import Validators from "../../../utils/Validators";
import { Redirect } from "react-router-dom";
import AuthApi from "../../../utils/api/AuthApi";
import FlashStore, {
  KEY_LOGIN,
  VALUE_PASSWORD_RESET
} from "../../../utils/FlashStore";
import { Analytics } from "../../../utils/Analytics";

class PageResetPassword extends BaseComponent {
  constructor(props) {
    super(props);
    Analytics.page("Reset password");

    this.onChangePasswordInput = this.onChangePasswordInput.bind(this);
    this.onChangeConfirmInput = this.onChangeConfirmInput.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);

    this.state = {
      password: "",
      confirmPassword: "",
      error: null,
      redirect: null
    };
  }

  onChangePasswordInput(event) {
    this.setState({ password: event.target.value, error: null });
  }

  onChangeConfirmInput(event) {
    this.setState({ confirmPassword: event.target.value, error: null });
  }

  onSubmitForm(event) {
    event.preventDefault();

    let error = null;
    if (this.state.password !== this.state.confirmPassword) {
      error = "Passwords do not match";
    }

    if (!Validators.isSecurePassword(this.state.password)) {
      error = "Please choose a password of at least 8 characters";
    }

    if (error == null) {
      const { code } = this.props.match.params;
      AuthApi.resetPassword(
        code,
        this.state.password,
        response => {
          FlashStore.put(KEY_LOGIN, VALUE_PASSWORD_RESET);
          this.setState({ redirect: "/login" });
        },
        error => {
          this.setState({ error: "Invalid password reset code provided  " });
        }
      );
    } else {
      this.setState({ error: error });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    let formError = null;
    if (this.state.error) {
      formError = <div className="form-error">{this.state.error}</div>;
    }

    return wrapInLoginBox(
      "/assets/images/login.svg",
      "Reset your password",
      <form onSubmit={this.onSubmitForm}>
        <div className="input-wrapper multiline">
          <input
            type="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.onChangePasswordInput}
            autoFocus={true}
          />
          <input
            type="password"
            placeholder="Confirm password"
            value={this.state.confirmPassword}
            onChange={this.onChangeConfirmInput}
          />
          <input type="submit" value="Change password" />
        </div>
      </form>,
      formError
    );
  }
}

export default PageResetPassword;
