import {
  TOKEN_UPDATED,
  CORE_DATA_LOADED,
  EMAIL_ENTERED,
  LOGOUT,
  BITS_LOADED,
  BIT_ADDED,
  BIT_ID_UPDATED,
  BIT_DELETED,
  BIT_EDITED,
  BIT_TOGGLED,
  BIT_PRIORITY_TOGGLED,
  BIT_MOVED,
  PRIORITY_LOADED,
  OVERDUE_LOADED,
  NOTEBOOK_LOADED,
  NOTEBOOK_BIT_LOADED,
} from "./actions";

import { initialState } from "./store";
import onEmailEntered from "./reducers/EmailEntered";
import onTokenUpdated from "./reducers/TokenUpdated";
import onCoreDataLoaded from "./reducers/CoreDataLoaded";
import onBitsLoaded from "./reducers/BitsLoaded";
import onLogout from "./reducers/Logout";
import onBitAdded from "./reducers/BitAdded";
import onBitIdUpdated from "./reducers/BitIdUpdated";
import onBitDeleted from "./reducers/BitDeleted";
import onBitEdited from "./reducers/BitEdited";
import onBitToggled from "./reducers/BitToggled";
import onBitPriorityToggled from "./reducers/BitPriorityToggled";
import onBitMoved from "./reducers/BitMoved";
import onPriorityBitsLoaded from "./reducers/OverdueBitsLoaded";
import onOverdueBitsLoaded from "./reducers/PriorityBitsLoaded";
import onNotebookLoaded from "./reducers/NotebookLoaded";
import onBitMovedNotebook from "./reducers/BitMovedNotebook";

export default function reducers(state = initialState, action) {
  /*const newState = reduce(state, action);
  console.log(newState);
  return newState;
}

function reduce(state, action) {*/
  switch (action.type) {
    case EMAIL_ENTERED:
      return onEmailEntered(state, action.payload);
    case TOKEN_UPDATED:
      return onTokenUpdated(state, action.payload);
    case CORE_DATA_LOADED:
      return onCoreDataLoaded(state, action.payload);
    case BITS_LOADED:
      return onBitsLoaded(state, action.payload);
    case BIT_ADDED:
      return onBitAdded(state, action.payload);
    case BIT_ID_UPDATED:
      return onBitIdUpdated(state, action.payload);
    case BIT_EDITED:
      return onBitEdited(state, action.payload);
    case BIT_TOGGLED:
      return onBitToggled(state, action.payload);
    case BIT_PRIORITY_TOGGLED:
      return onBitPriorityToggled(state, action.payload);
    case BIT_DELETED:
      return onBitDeleted(state, action.payload);
    case BIT_MOVED:
      return onBitMoved(state, action.payload);
    case PRIORITY_LOADED:
      return onPriorityBitsLoaded(state, action.payload);
    case OVERDUE_LOADED:
      return onOverdueBitsLoaded(state, action.payload);
    case NOTEBOOK_LOADED:
      return onNotebookLoaded(state, action.payload);
    case NOTEBOOK_BIT_LOADED:
      return onBitMovedNotebook(state, action.payload);
    case LOGOUT:
      return onLogout(state, action.payload);
    default:
      return state;
  }
}
