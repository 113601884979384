import React from "react";
import "./WeeklyOverdueWarning.scss";
import { connect } from "react-redux";
import BaseComponent from "../../BaseComponent";
import { normaliseMomentWeek } from "../../../utils/DateUtils";
import moment from "moment";

class WeeklyOverdueWarning_ extends BaseComponent {
  render() {
    const displayedDate = normaliseMomentWeek(
      moment(this.props.displayedDate, "YYYY-W")
    );

    const overdueBitCount = this.props.tasks.filter(
      it =>
        it.date != null &&
        it.dateObject.isSame(displayedDate, "isoWeek") &&
        it.isOverdue &&
        !it.isCurrentWeek &&
        it.done == null
    ).length;

    if (overdueBitCount === 0) {
      return null;
    }

    const bitWording = overdueBitCount === 1 ? "bit" : "bits";
    return (
      <div className="weekly-overdue-warning-wrapper">
        <div className="weekly-overdue-warning">
          <i className="fas fa-fw fa-calendar-day" />
          You have <strong>{overdueBitCount}</strong>&nbsp;overdue&nbsp;
          {bitWording} from this week.
        </div>
      </div>
    );
  }
}

const mapState = state => {
  return {
    tasks: state.bits
  };
};

const WeeklyOverdueWarning = connect(mapState, null)(WeeklyOverdueWarning_);
export default WeeklyOverdueWarning;
