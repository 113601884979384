import React from "react";
import moment from "moment";
import BaseComponent from "../../BaseComponent";
import PageHeader from "../../Elements/PageHeader/PageHeader";
import {
  formatDateYYYYW,
  normaliseMomentWeek
} from "../../../utils/DateUtils";

class PageBitsHeader extends BaseComponent {
  render() {
    const momentObject = normaliseMomentWeek(
      moment(this.props.displayedDate, "YYYY-W")
    );
    const formattedDate = momentObject.format("W of YYYY");

    const thisWeekPage = "/bits/" + formatDateYYYYW(this.props.currentDate);
    const previousWeekPage =
      "/bits/" + formatDateYYYYW(momentObject.clone().subtract(1, "week"));
    const nextWeekPage =
      "/bits/" + formatDateYYYYW(momentObject.clone().add(1, "week"));

    const pageOptions = this.createPageOptions([
      {
        link: previousWeekPage,
        icon: "fa-long-arrow-alt-left",
        tooltip: "Previous week",
        analytics: {
          name: "Bits: previous week"
        }
      },
      {
        link: thisWeekPage,
        icon: "fa-calendar-day",
        tooltip: "Jump to current week",
        analytics: {
          name: "Bits: jump to current week"
        }
      },
      {
        link: nextWeekPage,
        icon: "fa-long-arrow-alt-right",
        tooltip: "Next Week",
        analytics: {
          name: "Bits: next week"
        }
      }
    ]);

    const firstDayOfWeek = momentObject
      .clone()
      .startOf("isoWeek")
      .format("MMMM Do");
    const lastDayOfWeek = momentObject
      .clone()
      .endOf("isoWeek")
      .format("MMMM Do");

    return (
      <PageHeader
        title={"Week " + formattedDate}
        subtitle={firstDayOfWeek + " - " + lastDayOfWeek}
        options={pageOptions}
      />
    );
  }
}

export default PageBitsHeader;
