import moment from "moment";

export function formatDateYYYYW(date) {
  const momentObject = moment(date);
  return momentObject.isoWeekYear() + "-" + momentObject.isoWeek();
}

export function normaliseMomentWeek(moment) {
  return moment
    .clone()
    .isoWeekday(4)
    .hours(12)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);
}

export function normaliseMomentDay(moment) {
  return moment
    .clone()
    .hours(12)
    .minutes(0)
    .seconds(0)
    .milliseconds(0);
}
