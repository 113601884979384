import moment from "moment";
import { MODIFICATION_TYPE_TOGGLE } from "../../utils/Consts";

export default function onBitToggled(state, payload) {
  const bits = [...state.bits];

  const bitToToggle = bits.find(it => it.id === payload.id);
  if (bitToToggle != null) {
    if (bitToToggle.done == null) {
      setBitDone(bitToToggle, bits);
    } else {
      setBitUndone(bitToToggle, bits);
    }
  }

  return Object.assign({}, state, { bits: bits });
}

function setBitDone(bit, bits) {
  const isNotebook = bit.date == null;
  bit.done = moment().format();
  bit.modificationType = MODIFICATION_TYPE_TOGGLE;
  bit.modifiedAt = moment().unix();

  bits
    .filter(it => it.date == null && it.ordering > bit.ordering)
    .map(task => {
      task.ordering--;
      return task;
    });

  if (isNotebook) {
    bit.ordering = 0;
  }
}

function setBitUndone(bit, bits) {
  const isNotebook = bit.date == null;
  bit.done = null;

  if (isNotebook) {
    bit.ordering =
      bits.filter(it => it.date == null && it.done == null).length;
  }
}
