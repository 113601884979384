import React from "react";

import "./RegisterForm.scss";
import BaseComponent from "../../BaseComponent";
import Validators from "../../../utils/Validators";
import AuthApi from "../../../utils/api/AuthApi";
import { wrapInLoginBox } from "../UnauthComponent";
import { Link } from "react-router-dom";

class RegisterForm extends BaseComponent {
  constructor(props) {
    super(props);

    this.onChangeUsernameInput = this.onChangeUsernameInput.bind(this);
    this.onChangePasswordInput = this.onChangePasswordInput.bind(this);
    this.onChangeConfirmInput = this.onChangeConfirmInput.bind(this);
    this.onChangeLegalCheckbox = this.onChangeLegalCheckbox.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      error: null
    };
  }

  onChangeUsernameInput(event) {
    this.setState({ email: event.target.value, error: null });
  }

  onChangePasswordInput(event) {
    this.setState({ password: event.target.value, error: null });
  }

  onChangeConfirmInput(event) {
    this.setState({ confirmPassword: event.target.value, error: null });
  }

  onChangeLegalCheckbox(event) {
    this.setState({ legalCheckboxChecked: event.target.checked, error: null });
  }

  onSubmitForm(event) {
    event.preventDefault();

    const { email, password, confirmPassword } = this.state;

    let error = null;
    if (!this.state.legalCheckboxChecked) {
      error = "You need to accept our Privacy Policy and Terms of Service";
    } else if (!Validators.isEmail(email)) {
      error = "Please enter a valid email address";
    } else if (!Validators.isSecurePassword(password)) {
      error = "Please choose a password of at least 8 characters";
    } else if (password !== confirmPassword) {
      error = "Passwords do not match";
    }

    if (error == null) {
      AuthApi.register(
        email,
        password,
        _ => this.props.onRegistrationSubmitted(email),
        error => this.handleError(error ? error.data.error : "")
      );
    } else {
      this.setState({ error: error });
    }
  }

  handleError(errorCode) {
    if (errorCode === "email already taken") {
      this.setState({
        password: "",
        confirmPassword: "",
        error: "This email address is already registered. "
      });
    } else if (errorCode === "invalid invite code") {
      this.setState({
        password: "",
        confirmPassword: "",
        error: "This invite code has already been used"
      });
    }
  }

  render() {
    let formError = null;
    if (this.state.error) {
      formError = <div className="form-error">{this.state.error}</div>;
    }

    return wrapInLoginBox(
      "/assets/images/register.svg",
      "Create new account",
      <React.Fragment>
        <form onSubmit={this.onSubmitForm}>
          <div className="input-wrapper multiline">
            <input
              type="text"
              placeholder="Email"
              value={this.state.email}
              onChange={this.onChangeUsernameInput}
              autoFocus={true}
            />
            <input
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.onChangePasswordInput}
            />
            <input
              type="password"
              placeholder="Confirm password"
              value={this.state.confirmPassword}
              onChange={this.onChangeConfirmInput}
            />

            <div className="legal-wrapper">
              <input
                type="checkbox"
                className="checkbox-legal"
                id="checkbox-legal"
                onChange={this.onChangeLegalCheckbox}
              />
              <label htmlFor="checkbox-legal">
                I have read and agree to the{" "}
                <a
                  href="https://donemybit.com/legal/donemybit.privacy.policy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy&nbsp;Policy
                </a>{" "}
                and to the{" "}
                <a
                  href="https://donemybit.com/legal/donemybit.terms.of.service.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms&nbsp;of&nbsp;Service
                </a>
                .
              </label>
            </div>

            <input type="submit" value="Create account" />
          </div>
        </form>
        <ul className="login-options">
          <li>
            Already have an account?&nbsp;<Link to="/login">Log in</Link>
          </li>
        </ul>
      </React.Fragment>,
      formError
    );
  }
}

export default RegisterForm;
