import React from "react";
import BaseComponent from "../../BaseComponent";
import "./PageForgottenPassword.scss";
import ForgottenPasswordForm from "./ForgottenPasswordForm";
import ForgottenPasswordSubmitted from "./ForgottenPasswordSubmitted";
import { Analytics } from "../../../utils/Analytics";

class PageForgottenPassword extends BaseComponent {
  constructor(props) {
    super(props);
    Analytics.page("Forgotten password");

    this.onEmailSubmitted = this.onEmailSubmitted.bind(this);

    this.state = {
      submitted: false
    };
  }

  onEmailSubmitted() {
    this.setState({ submitted: true });
  }

  render() {
    if (this.state.submitted) {
      return <ForgottenPasswordSubmitted />;
    } else {
      return <ForgottenPasswordForm onEmailSubmitted={this.onEmailSubmitted} />;
    }
  }
}

export default PageForgottenPassword;
