import React from "react";
import "./UserAvatar.scss";

class UserAvatar extends React.Component {
  render() {
    let display = null;
    if (this.props.user) {
      display = this.props.user.initials;
    } else if (this.props.icon) {
      display = <i className={this.props.icon} />;
    }

    let avatarClass = "user-avatar";
    if (this.props.size) {
      avatarClass = avatarClass + " size-" + this.props.size;
    }

    return <div className={avatarClass}>{display}</div>;
  }
}

export default UserAvatar;
