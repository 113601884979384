import React from "react";
import "./BitStatusIcon.scss";
import BaseComponent from "../../BaseComponent";

class BitStatusIcon extends BaseComponent {
  userClicked = false;

  onIconClicked() {
    this.userClicked = true;
    this.props.onIconClicked();
  }

  render() {
    let iconClass = "icon";
    if (this.props.size) {
      iconClass += " " + this.props.size;
    }

    let statusNode;
    if (this.props.done) {
      let iClass = "fa-fw fas fa-check status-done";
      if (this.userClicked) {
        iClass += " new";
        this.userClicked = false;
      }
      statusNode = <i className={iClass} />;
    } else {
      statusNode = <i className="status-pending">&nbsp;</i>;
    }

    return (
      <div className={iconClass} onClick={() => this.onIconClicked()}>
        {statusNode}
      </div>
    );
  }
}

export default BitStatusIcon;
