import React from "react";
import "./BaseNavigationBar.scss";
import BaseComponent from "../../BaseComponent";
import { NavLink } from "react-router-dom";
import MemCache from "../../../utils/MemCache";

class BaseNavigationBar extends BaseComponent {
  createOptions() {
    return (
      <ul className="navigation-options">
        <li>
          <NavLink activeClassName="active" to="/bits">
            <i className="fas fa-home fa-fw" />
            <span className="navigation-option-name">&nbsp;Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/priority">
            <i className="fas fa-star fa-fw" />
            <span className="navigation-option-name">&nbsp;Priority</span>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/overdue">
            <i className="fas fa-calendar-day fa-fw" />
            <span className="navigation-option-name">&nbsp;Overdue</span>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/notebook">
            <i className="fas fa-book fa-fw" />
            <span className="navigation-option-name">&nbsp;Notebook</span>
          </NavLink>
        </li>
      </ul>
    );
  }

  createProfileBox() {
    const user = MemCache.getUser();
    return (
      <div className="navigation-options-sub-wrapper">
        <ul className="optionsSub">
          <li>
            <NavLink activeClassName="active" to="/profile">
              <div className="profile-box">
                <i className="fas fa-user fa-fw" />
                <div className="navigation-option-name">
                  <div className="user">{user.displayName}</div>
                </div>
              </div>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default BaseNavigationBar;
