import React from "react";
import "./Main.scss";
import BaseComponent from "../BaseComponent";
import Authenticated from "./Authenticated";
import Unauthenticated from "./Unauthenticated";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { COOKIE_TOKEN } from "../../utils/Cookies";
import { TOKEN_UPDATED } from "../../redux/actions";

class Main_ extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingAuthState: true
    };
  }

  componentDidMount() {
    if (!this.props.token) {
      const cookieStoredToken = Cookies.get(COOKIE_TOKEN);
      if (cookieStoredToken) {
        const { onAuthenticated } = this.props;
        onAuthenticated(cookieStoredToken);
      }

      this.setState({ isLoadingAuthState: false });
    }
  }

  render() {
    if (this.props.token) {
      return <Authenticated />;
    } else if (!this.state.isLoadingAuthState) {
      return <Unauthenticated />;
    } else {
      return null;
    }
  }
}

const mapState = state => {
  return {
    token: state.token
  };
};

const dispatchState = dispatch => {
  return {
    onAuthenticated: token =>
      dispatch({
        type: TOKEN_UPDATED,
        payload: { token: token }
      })
  };
};

const Main = connect(mapState, dispatchState)(Main_);

export default Main;
