import React from "react";

import BaseComponent from "../../BaseComponent";
import AuthApi from "../../../utils/api/AuthApi";
import { Redirect } from "react-router-dom";
import FlashStore, {
  KEY_LOGIN,
  VALUE_REGISTRATION_CONFIRMED
} from "../../../utils/FlashStore";

class PageConfirmRegistration extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      confirmed: false
    };
  }

  componentDidMount() {
    const { code } = this.props.match.params;

    AuthApi.confirm(
      code,
      response => {
        FlashStore.put(KEY_LOGIN, VALUE_REGISTRATION_CONFIRMED);
        this.setState({ confirmed: true });
      },
      error => {
        this.setState({ confirmed: true });
      }
    );
  }

  render() {
    if (this.state.confirmed) {
      return <Redirect to="/" />;
    } else {
      return null;
    }
  }
}

export default PageConfirmRegistration;
