/*÷***************************
 *          ACTIONS          *
 *****************************/

export const EMAIL_ENTERED = "auth-email-entered";
export const TOKEN_UPDATED = "token-updated";
export const CORE_DATA_LOADED = "core-data-loaded";

export const BITS_LOADED = "bits-loaded";
export const BIT_ADDED = "bit-added";
export const BIT_ID_UPDATED = "bit-id-updated";
export const BIT_EDITED = "bit-edited";
export const BIT_DELETED = "bit-deleted";
export const BIT_TOGGLED = "bit-toggled";
export const BIT_PRIORITY_TOGGLED = "bit-priority-toggled";
export const BIT_MOVED = "bit-moved";

export const PRIORITY_LOADED = "priority-loaded";
export const OVERDUE_LOADED = "overdue-loaded";

export const NOTEBOOK_LOADED = "notebook-loaded";
export const NOTEBOOK_BIT_LOADED = "notebook-bit-moved";

export const LOGOUT = "user-logout";
