import React from "react";
import BaseComponent from "../../BaseComponent";
import "./PageHeader.scss";

class PageHeader extends BaseComponent {
  render() {
    const pageOptions = this.props.options;
    let pageOptionsNode = null;
    if (pageOptions) {
      pageOptionsNode = <ul className="pageOptions">{pageOptions}</ul>;
    }

    const subtitle = this.props.subtitle;
    let subtitleNode = null;
    if (subtitle) {
      subtitleNode = <h2>{subtitle}</h2>;
    }

    return (
      <div className="pageHeaderWrapper">
        <div className="pageHeader">
          <div className="pageHeaderTitleWrapper">
            <h1>{this.props.title}</h1>
            {subtitleNode}
          </div>
          {pageOptionsNode}
        </div>
      </div>
    );
  }
}

export default PageHeader;
