import React from "react";
import "./NewBitFloatingPanel.scss";
import NewBitInput from "../NewBitInput/NewBitInput";

class NewBitFloatingPanel extends React.Component {
  render() {
    let className = "new-bit-floating-panel-wrapper";
    if (this.props.hidden) {
      className += " hidden";
    }

    return (
      <div className={className}>
        <div className="new-bit-floating-panel">
          <NewBitInput
            hidden={this.props.hidden}
            displayedDate={this.props.displayedDate}
            hideNewBitForm={this.props.hideNewBitForm}
          />
        </div>
      </div>
    );
  }
}

export default NewBitFloatingPanel;
