import BaseApi from "./BaseApi";
import { Analytics } from "../Analytics";
import MemCache from "../MemCache";

class BitsApi extends BaseApi {
  static getBits(date, then) {
    return this.get(`/bits/${date}`, then);
  }

  static addBit(tempId, text, date, then) {
    Analytics.action("Bit added", {
      bitLength: text.length,
      notebook: date == null
    });

    let apiEndpointPath;
    if (date == null) {
      apiEndpointPath = "/notebook/add";
    } else {
      apiEndpointPath = `/bits/add/${date}`;
    }

    return this.postImmediate(
      apiEndpointPath,
      { tempId: tempId, text: text },
      then
    );
  }

  static deleteBit(id, then) {
    Analytics.action("Bit deleted");
    return this.postImmediate("/bits/delete", { taskId: id }, then);
  }

  static editBit(id, text, then) {
    Analytics.action("Bit edited");
    return this.postImmediate("/bits/edit", { taskId: id, text: text }, then);
  }

  static moveBit(id, fromDateMomentObject, fromPosition, toDateMomentObject, toPosition, postponed, then) {
    return this.postImmediate(
      "/bits/move",
      {
        taskId: id,
        fromDate: fromDateMomentObject.format("YYYY-MM-DD"),
        fromPosition: fromPosition,
        toDate: toDateMomentObject.format("YYYY-MM-DD"),
        toPosition: toPosition,
        mnonce: MemCache.getUser().mnonce
      },
      response => {
        if (response.mnonce) {
          Analytics.action("Bit moved", {
            isNotebook: 0,
            fromPostpone: postponed ? 1 : 0,
            dayDifference: toDateMomentObject.diff(fromDateMomentObject, "days"),
            positionDifference: toPosition - fromPosition
          });

          MemCache.updateUser({ mnonce: response.mnonce });
        } else {
          Analytics.track("Invalid nonce", { nonceType: "move-bit" });
        }

        if (then != null) {
          then(response);
        }
      }
    );
  }

  static moveBitNotebook(id, fromPosition, toPosition, then) {
    return this.postImmediate(
      "/notebook/move",
      {
        taskId: id,
        fromPosition: fromPosition,
        toPosition: toPosition,
        mnonce: MemCache.getUser().mnonce
      },
      response => {
        if (response.mnonce) {
          Analytics.action("Bit moved", {
            isNotebook: 1,
            positionDifference: toPosition - fromPosition
          });

          MemCache.updateUser({ mnonce: response.mnonce });
        } else {
          Analytics.track("Invalid nonce", { nonceType: "move-bit" });
        }

        if (then != null) {
          then(response);
        }
      }
    );
  }

  static toggleBit(id, then) {
    Analytics.action("Bit toggled");
    return this.postImmediate("/bits/toggle", { taskId: id }, then);
  }

  static togglePriority(id, then) {
    Analytics.action("Bit priority toggled");
    return this.postImmediate("/bits/priority", { taskId: id }, then);
  }
}

export default BitsApi;
