import React from "react";
import BaseComponent from "../../BaseComponent";
import "./AddBitFloatingButton.scss";

class AddBitFloatingButton extends BaseComponent {
  render() {
    let className = "add-bit-floating";
    if (this.props.hidden) {
      className += " hidden";
    }

    return (
      <div className={className} onClick={this.props.onClick}>
        <i className="fas fa-fw fa-plus" />
      </div>
    );
  }
}

export default AddBitFloatingButton;
