import React from "react";
import "./BitPriorityIcon.scss";
import moment from "moment";

class BitPriorityIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      madePriorityAt: -1
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.priority && this.props.priority) {
      this.setState({ madePriorityAt: moment().unix() });
    }
  }

  render() {
    let iClass;
    if (this.props.priority) {
      iClass = "fas fa-fw fa-star";

      if (moment().unix() - this.state.madePriorityAt <= 1) {
        iClass += " new-priority";
      }
    } else {
      iClass = "far fa-fw fa-star";
    }

    return (
      <div className="priority-icon" onClick={() => this.props.onIconClicked()}>
        <i className={iClass} />
      </div>
    );
  }
}

export default BitPriorityIcon;
