import React from "react";
import "./PageBits.scss";
import { connect } from "react-redux";
import BitList from "../../Elements/BitList/BitList";
import PageBitsHeader from "./PageBitsHeader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BaseComponent from "../../BaseComponent";
import BitsApi from "../../../utils/api/BitsApi";
import { Analytics } from "../../../utils/Analytics";
import moment from "moment";
import TimelineBadge from "../../Elements/TimelineBadge/TimelineBadge";
import MonthSeparator from "../../Elements/MonthSeparator/MonthSeparator";
import AddBitFloatingButton from "../../Elements/AddBitFloatingButton/AddBitFloatingButton";
import NewBitFloatingPanel from "../../Elements/NewBitFloatingPanel/NewBitFloatingPanel";
import { formatDateYYYYW } from "../../../utils/DateUtils";
import { BIT_MOVED, BITS_LOADED } from "../../../redux/actions";
import { DragDropContext } from "react-beautiful-dnd";
import { OPTION_JUMP_TO_BIT } from "../../Elements/BitListItemOptions/BitListItemOptions";
import WeeklyOverdueWarning from "../../Elements/WeeklyOverdueWarning/WeeklyOverdueWarning";

class PageBits_ extends BaseComponent {
  constructor(props) {
    super(props);
    Analytics.page("Bits");

    this.showNewBitForm = this.showNewBitForm.bind(this);
    this.hideNewBitForm = this.hideNewBitForm.bind(this);
    this.onBitsReordered = this.onBitsReordered.bind(this);

    this.state = {
      displayedDate: null,
      showNewBitForm: false
    };
  }

  componentDidMount() {
    this.loadDataIfNotLoaded();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const dateToDisplay = this.getDateToDisplay();
    if (
      dateToDisplay !== this.state.displayedDate &&
      this.state.displayedDate !== ""
    ) {
      this.loadDataIfNotLoaded();
    }
  }

  showNewBitForm() {
    this.setState({ showNewBitForm: true });
  }

  hideNewBitForm() {
    this.setState({ showNewBitForm: false });
  }

  onBitsReordered(result) {
    if (result.destination == null) {
      return;
    }

    const displayedDateSplit = this.state.displayedDate.split("-");
    const year = parseInt(displayedDateSplit[0]);
    const week = parseInt(displayedDateSplit[1]);

    const source = {
      year: year,
      week: week,
      weekdayIndex: parseInt(result.source.droppableId),
      position: result.source.index
    };
    const destination = {
      year: year,
      week: week,
      weekdayIndex: parseInt(result.destination.droppableId),
      position: Math.max(result.destination.index, 1)
    };

    if (
      source.weekdayIndex === destination.weekdayIndex &&
      source.position === destination.position
    ) {
      return;
    }

    const bitId = parseInt(result.draggableId);

    const fromDate = moment()
      .year(year)
      .isoWeek(week)
      .isoWeekday(source.weekdayIndex);
    const toDate = moment()
      .year(year)
      .isoWeek(week)
      .isoWeekday(destination.weekdayIndex);
    BitsApi.moveBit(
      bitId,
      fromDate,
      source.position,
      toDate,
      destination.position,
      false
    );

    this.props.onBitMoved({
      bitId: bitId,
      source: source,
      destination: destination
    });
  }

  loadDataIfNotLoaded() {
    const dateToDownload = this.getDateToDisplay();

    if (!this.props.weeksLoaded.includes(dateToDownload)) {
      BitsApi.getBits(dateToDownload, response => {
        const splitDate = dateToDownload.split("-");
        Object.assign(response, { year: splitDate[0], week: splitDate[1] });
        this.props.onBitsLoaded(response);
      });
    }

    this.setState({ displayedDate: dateToDownload });
  }

  getDateToDisplay() {
    let date = this.props.match.params.date;
    if (date == null) {
      date = formatDateYYYYW(this.props.currentDate);
    }

    return date;
  }

  createUserTaskItems(tasks) {
    const year = parseInt(this.state.displayedDate.split("-")[0]);
    const weekOfYear = parseInt(this.state.displayedDate.split("-")[1]);

    return [1, 2, 3, 4, 5, 6, 7].map(isoWeekDayIndex => {
      const currentNodeDate = moment()
        .year(year)
        .isoWeek(weekOfYear)
        .isoWeekday(isoWeekDayIndex);
      const currentNodeMonth = currentNodeDate.month();
      const nextNodeMonth = currentNodeDate
        .clone()
        .isoWeekday(isoWeekDayIndex + 1)
        .month();

      let monthSeparatorNode = null;
      if (currentNodeMonth !== nextNodeMonth) {
        monthSeparatorNode = (
          <MonthSeparator
            key={"month-separator-" + currentNodeMonth}
            month={currentNodeDate.format("MMMM")}
          />
        );
      }

      const dailyTasks = tasks.filter(
        task => moment(task.date).isoWeekday() === isoWeekDayIndex
      );

      const date = moment()
        .year(year)
        .isoWeek(weekOfYear)
        .isoWeekday(isoWeekDayIndex);
      const isToday = date.isSame(moment(), "day");

      return (
        <React.Fragment key={"wrapper-" + isoWeekDayIndex}>
          <Row key={isoWeekDayIndex} className="userBox">
            <Col sm={1} md={2} lg={1} className="user">
              <TimelineBadge date={date} isToday={isToday} />
            </Col>
            <Col sm={11} md={10} lg={11} className="bit-list no-padding">
              <BitList
                tasks={dailyTasks}
                droppableId={isoWeekDayIndex}
                hideItemOptions={[OPTION_JUMP_TO_BIT]}
              />
            </Col>
          </Row>
          {monthSeparatorNode}
        </React.Fragment>
      );
    });
  }

  render() {
    if (this.state.displayedDate == null) {
      return null;
    }

    const dateToDisplaySplit = this.state.displayedDate.split("-");
    const yearToDisplay = parseInt(dateToDisplaySplit[0]);
    const weekToDisplay = parseInt(dateToDisplaySplit[1]);

    const bitsToDisplay = this.props.bits.filter(
      it => it.year === yearToDisplay && it.week === weekToDisplay
    );

    const bitsDownloaded =
      bitsToDisplay.length > 0 ||
      this.props.weeksLoaded.includes(this.state.displayedDate);
    let taskItemsNode = null;
    if (bitsDownloaded) {
      taskItemsNode = this.createUserTaskItems(bitsToDisplay);
    }

    return (
      <React.Fragment>
        <PageBitsHeader
          displayedDate={this.state.displayedDate}
          currentDate={this.props.currentDate}
        />
        <Container className="page-content">
          <WeeklyOverdueWarning displayedDate={this.state.displayedDate} />
          <DragDropContext onDragEnd={this.onBitsReordered}>
            {taskItemsNode}
          </DragDropContext>
        </Container>
        <AddBitFloatingButton
          hidden={this.state.showNewBitForm}
          onClick={() => this.showNewBitForm()}
        />
        <NewBitFloatingPanel
          hidden={!this.state.showNewBitForm}
          displayedDate={this.state.displayedDate}
          hideNewBitForm={this.hideNewBitForm}
        />
      </React.Fragment>
    );
  }
}

const mapState = state => {
  return {
    currentDate: state.currentDate,
    weeksLoaded: state.weeksLoaded,
    bits: state.bits.filter(it => it.date != null)
  };
};

const mapDispatch = dispatch => {
  return {
    onBitsLoaded: loadedData =>
      dispatch({
        type: BITS_LOADED,
        payload: loadedData
      }),
    onBitMoved: data =>
      dispatch({
        type: BIT_MOVED,
        payload: data
      })
  };
};

const PageBits = connect(mapState, mapDispatch)(PageBits_);

export default PageBits;
