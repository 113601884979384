class Validators {
  static emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  static isEmail(subject) {
    return this.emailRegex.test(subject);
  }

  static isSecurePassword(password) {
    return password.length >= 8;
  }

  static isDisplayName(displayName) {
    return displayName.length >= 2;
  }

  static isTeamName(teamName) {
    return teamName.length >= 2;
  }
}

export default Validators;
