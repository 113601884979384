import React from "react";
import BaseComponent from "../../BaseComponent";
import "./BitListItemOptions.scss";
import { connect } from "react-redux";
import { BIT_DELETED, BIT_MOVED } from "../../../redux/actions";
import BitsApi from "../../../utils/api/BitsApi";
import moment from "moment";
import { Redirect } from "react-router-dom";

class BitListItemOptions_ extends BaseComponent {
  constructor(props) {
    super(props);

    this.jumpToDate = this.jumpToDate.bind(this);
    this.onPostponeClicked = this.onPostponeClicked.bind(this);
    this.onDeleteBitClicked = this.onDeleteBitClicked.bind(this);
    this.showOption = this.showOption.bind(this);

    this.state = {
      redirect: null
    };
  }

  jumpToDate() {
    this.setState({ redirect: this.getTaskLink(this.props.task) });
  }

  onPostponeClicked() {
    const bitId = this.props.task.id;
    const nextMonday = moment(this.props.task.date)
      .add(1, "week")
      .isoWeekday(1);

    BitsApi.moveBit(
      bitId,
      moment(this.props.task.date),
      this.props.task.ordering,
      nextMonday,
      1,
      true
    );

    this.props.onBitMoved({
      bitId: bitId,
      source: {
        year: this.props.task.year,
        week: this.props.task.week,
        weekdayIndex: this.props.task.weekday,
        position: this.props.task.ordering
      },
      destination: {
        year: nextMonday.year(),
        week: nextMonday.isoWeek(),
        weekdayIndex: nextMonday.isoWeekday(),
        position: 1
      }
    });
  }

  onDeleteBitClicked() {
    this.props.onBitDeleted(this.props.task.id);
    BitsApi.deleteBit(this.props.task.id);
  }

  showOption(option) {
    return (
      this.props.hideItemOptions == null ||
      !this.props.hideItemOptions.includes(option)
    );
  }

  render() {
    if (this.state.redirect != null) {
      return <Redirect to={this.state.redirect} />;
    }

    let jumpToBitOptionNode = null;
    if (this.showOption(OPTION_JUMP_TO_BIT)) {
      jumpToBitOptionNode = this.withOverlay(
        <li onClick={() => this.jumpToDate()}>
          <i className="fas fa-fw fa-calendar-day" />
        </li>,
        "jump-to-bit",
        "Jump to bit"
      );
    }

    let postponeOptionNode = null;
    if (this.showOption(OPTION_POSTPONE)) {
      postponeOptionNode = this.withOverlay(
        <li onClick={() => this.onPostponeClicked()}>
          <i className="fas fa-fw fa-share" />
        </li>,
        "postpone",
        "Postpone to next week"
      );
    }

    const deleteOptionNode = this.withOverlay(
      <li onClick={() => this.onDeleteBitClicked()}>
        <i className="fas fa-fw fa-times" />
      </li>,
      "delete",
      "Delete"
    );

    return (
      <React.Fragment>
        <div className="task-options">
          <ul>
            {jumpToBitOptionNode}
            {postponeOptionNode}
            {deleteOptionNode}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = dispatch => {
  return {
    onBitMoved: data =>
      dispatch({
        type: BIT_MOVED,
        payload: data
      }),
    onBitDeleted: id =>
      dispatch({
        type: BIT_DELETED,
        payload: { id: id }
      })
  };
};

const BitListItemOptions = connect(null, mapDispatch)(BitListItemOptions_);

export default BitListItemOptions;
export const OPTION_JUMP_TO_BIT = "jump-to-bit";
export const OPTION_POSTPONE = "postpone";
