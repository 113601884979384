import React from "react";
import BaseComponent from "../../BaseComponent";
import "./PageLogin.scss";
import AuthApi from "../../../utils/api/AuthApi";
import { connect } from "react-redux";
import { EMAIL_ENTERED, TOKEN_UPDATED } from "../../../redux/actions";
import { Link } from "react-router-dom";
import Validators from "../../../utils/Validators";
import { wrapInLoginBox } from "../UnauthComponent";
import FlashStore, {
  KEY_LOGIN,
  VALUE_PASSWORD_RESET,
  VALUE_REGISTRATION_CONFIRMED
} from "../../../utils/FlashStore";
import FlashMessage from "../../Elements/FlashMessage/FlashMessage";
import { Analytics } from "../../../utils/Analytics";

class PageLogin_ extends BaseComponent {
  constructor(props) {
    super(props);
    Analytics.page("Login");

    this.onChangeUsernameInput = this.onChangeUsernameInput.bind(this);
    this.onChangePasswordInput = this.onChangePasswordInput.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);

    this.state = {
      password: "",
      error: null
    };
  }

  onChangeUsernameInput(event) {
    const { onEmailEntered } = this.props;
    onEmailEntered(event.target.value);

    this.setState({ error: null });
  }

  onChangePasswordInput(event) {
    this.setState({ password: event.target.value, error: null });
  }

  onSubmitForm(event) {
    event.preventDefault();

    if (!Validators.isEmail(this.props.email)) {
      this.setState({
        error: "Please enter your e-mail"
      });
      return;
    }

    if (this.state.password.length === 0) {
      this.setState({
        error: "Please enter your password"
      });
      return;
    }

    const { onAuthenticated } = this.props;

    AuthApi.login(
      this.props.email,
      this.state.password,
      response => {
        const { token } = response;
        onAuthenticated(token);
      },
      error => {
        let errorMessage;
        if (error.data.error === "inactive account") {
          errorMessage = "Please confirm your email address first";
        } else {
          errorMessage = "Incorrect username or password. Please try again";
        }

        this.setState({
          password: "",
          error: errorMessage
        });
      }
    );
  }

  render() {
    let formError = null;
    if (this.state.error) {
      formError = <div className="form-error big">{this.state.error}</div>;
    }

    const flashValue = FlashStore.get(KEY_LOGIN);
    let flashMessageItem = null;
    if (flashValue === VALUE_PASSWORD_RESET) {
      flashMessageItem = (
        <FlashMessage>
          <i className="fas fa-fw fa-check-circle" /> Your password was updated
          successfully
        </FlashMessage>
      );
    } else if (flashValue === VALUE_REGISTRATION_CONFIRMED) {
      flashMessageItem = (
        <FlashMessage>
          <i className="fas fa-fw fa-check-circle" /> Your account is now active
          - please log in!
        </FlashMessage>
      );
    }

    return wrapInLoginBox(
      "/assets/images/welcome.svg",
      "Hello again!",
      <React.Fragment>
        {flashMessageItem}
        <div className="login-flavour">
          Please log in to your DoneMyBit account.
        </div>
        <form onSubmit={this.onSubmitForm}>
          <div className="input-wrapper multiline">
            <input
              type="text"
              placeholder="Email"
              value={this.props.email}
              onChange={this.onChangeUsernameInput}
              autoFocus={true}
            />
            <input
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.onChangePasswordInput}
            />
            <input type="submit" value="Log in" />
          </div>
        </form>
        <ul className="login-options">
          <li>
            <Link to="/register">Create new account</Link>
          </li>
          <li>
            <Link to="/forgot">Forgot password</Link>
          </li>
        </ul>
      </React.Fragment>,
      formError
    );
  }
}

const mapState = state => {
  return {
    email: state.email
  };
};

const mapDispatch = dispatch => {
  return {
    onEmailEntered: email =>
      dispatch({
        type: EMAIL_ENTERED,
        payload: { email: email }
      }),

    onAuthenticated: token =>
      dispatch({
        type: TOKEN_UPDATED,
        payload: { token: token }
      })
  };
};

const PageLogin = connect(mapState, mapDispatch)(PageLogin_);

export default PageLogin;
