import moment from "moment";
import { MODIFICATION_TYPE_DEFAULT } from "../../utils/Consts";

export default function onBitEdited(state, payload) {
  const bits = [...state.bits];

  const bitToEdit = bits.find(it => it.id === payload.id);
  if (bitToEdit != null) {
    bitToEdit.text = payload.newText;
    bitToEdit.modificationType = MODIFICATION_TYPE_DEFAULT;
    bitToEdit.modifiedAt = moment().unix();
  }

  return Object.assign({}, state, { bits: bits });
}
