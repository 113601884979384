import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PageLogin from "../Pages/PageLogin/PageLogin";
import PageRegister from "../Pages/PageRegister/PageRegister";
import PageForgottenPassword from "../Pages/PageForgottenPassword/PageForgottenPassword";
import PageResetPassword from "../Pages/PageResetPassword/PageResetPassword";
import PageConfirmRegistration from "../Pages/PageConfirmRegistration/PageConfirmRegistration";

class Unauthenticated extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/login" component={PageLogin} />
          <Route
            path="/register/confirm/:code"
            component={PageConfirmRegistration}
          />
          <Route path="/register" component={PageRegister} />
          <Route path="/forgot/:code" component={PageResetPassword} />
          <Route path="/forgot" component={PageForgottenPassword} />

          <Route>
            <Redirect to="/login" />
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
}

export default Unauthenticated;
