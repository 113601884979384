import MemCache from "../../utils/MemCache";

export default function onCoreDataLoaded(state, payload) {
  MemCache.setUser(payload.user);

  // TODO as part of CoreData, download current week + previous 2 + next 2
  return Object.assign({}, state, {
    userId: payload.user.id,
    currentDate: payload.currentDate
  });
}
