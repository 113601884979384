import React from "react";
import "./PageDashboard.scss";
import { Redirect } from "react-router-dom";

class PageDashboard extends React.Component {
  render() {
    return <Redirect to="/bits" />;
  }
}

export default PageDashboard;
